import React from 'react';
import styled from 'styled-components';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  TumblrShareButton,
  TumblrIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';

const SocialMediaContainer = styled.div`
  display: inline-block;
  margin: 1rem 0;
  font-size: 2rem;

  & * {
    margin: 0.1rem;
  }
`;

const SocialMedia = props => {
  const size = 30;
  const borderRadius = 10;

  return (
    <SocialMediaContainer>
      <FacebookShareButton
        url={props.url}
        quote={`Check out this awesome blog post by Willem Deen: ${props.title}`}
      >
        <FacebookIcon size={size} borderRadius={borderRadius} />
      </FacebookShareButton>

      <TwitterShareButton url={props.url} title={props.title}>
        <TwitterIcon size={size} borderRadius={borderRadius} />
      </TwitterShareButton>

      <WhatsappShareButton url={props.url} title={props.title} separator={'\n'}>
        <WhatsappIcon size={size} borderRadius={borderRadius} />
      </WhatsappShareButton>

      <TelegramShareButton url={props.url} title={props.title}>
        <TelegramIcon size={size} borderRadius={borderRadius} />
      </TelegramShareButton>

      <LinkedinShareButton
        url={props.url}
        title={props.title}
        source={'willemdeen.com'}
      >
        <LinkedinIcon size={size} borderRadius={borderRadius} />
      </LinkedinShareButton>

      <RedditShareButton
        url={props.url}
        title={props.title}
        source={'willemdeen.com'}
      >
        <RedditIcon size={size} borderRadius={borderRadius} />
      </RedditShareButton>

      <TumblrShareButton url={props.url} title={props.title} tags={props.tags}>
        <TumblrIcon size={size} borderRadius={borderRadius} />
      </TumblrShareButton>

      <EmailShareButton
        url={props.url}
        subject={props.title}
        body={`Check out this awesome blog post by Willem Deen: ${props.title}`}
        separator={'\n'}
      >
        <EmailIcon size={size} borderRadius={borderRadius} />
      </EmailShareButton>
    </SocialMediaContainer>
  );
};

export default SocialMedia;
