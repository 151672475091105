import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import variables from '../styles/variables';

const StyledLink = styled(Link)`
  display: inline-block;
  position: fixed;
  top: 0;
  left: 16rem;
  margin: 4rem 0;
  padding: 0.2rem 0.5rem;
  color: ${props => props.theme.colorButtonBackground};
  background: ${props => props.theme.colorPrimaryDark};
  transition: color 0.2s;
  box-shadow: 0 0 10px 10px #000000;
  border: 1px solid ${props => props.theme.colorPrimaryLight};
  border-radius: 5px;

  &:hover {
    color: ${props => props.theme.colorSecondaryLight};
  }

  @media only screen and (max-width: ${variables.screenWidth}) {
    top: unset;
    right: 4rem;
    bottom: 0;
    left: unset;
  }
`;

const BackButton = ({ to, children }) => {
  return <StyledLink to={to}>{children}</StyledLink>;
};

export default BackButton;
