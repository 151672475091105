import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import { MDXProvider } from '@mdx-js/react';

import Layout from '../components/layout';
import SocialMedia from '../components/socialMedia';
import BackButton from '../components/backButton';

const Container = styled.div`
  max-width: 1200px;
`;

const TitleBarContainer = styled.div`
  margin-bottom: 3rem;
`;

const Title = styled.h1`
  font-size: 4rem;
  text-shadow: 1px 1px 0px ${props => props.theme.colorPrimaryDark};
  color: ${props => props.theme.colorPrimaryLight};
`;

const TimeInfo = styled.p`
  font-size: 1.2rem;
  color: ${props => props.theme.colorTextLight};
`;

const Paragraph = styled.p`
  margin: 2rem 0;
`;

// const Pre = styled.pre`
//   padding: 1rem;
//   margin: 2rem 0;
//   display: block;
//   border-radius: 5px;
//   overflow-x: auto;
//   font-family: 'Roboto Mono', monospace;
//   font-size: 1.5rem;
//   background-color: ${props => props.theme.colorPrimaryDark}66;
// `;

const Code = styled.code`
  padding: 0 0.5rem;
`;

const Ul = styled.ul`
  margin-left: 4rem;
  padding-left: 0;
  margin-bottom: 2rem;
`;

const Ol = styled.ol`
  margin-left: 4rem;
  padding-left: 0;
  margin-bottom: 2rem;
`;

const Blockquote = styled.blockquote`
  margin: 2rem;
  padding-left: 1rem;
  font-style: italic;
  border-left: 1rem solid ${props => props.theme.colorPrimaryDark};
  text-shadow: 1px 1px 0px ${props => props.theme.colorPrimaryLight};
`;

const A = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: ${props => props.theme.colorSecondaryLight};
  }
`;

const Audio = styled.audio.attrs({
  controlsList: 'nodownload',
})`
  padding: 1.2rem;
  border-radius: 0;
  width: 100%;
`;

const Img = styled.img`
`;

export const query = graphql`
  query ($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      updatedAt(formatString: "MMMM Do. YYYY")
      body {
        childMdx {
          body
        }
      }
    }
  }
`;

const components = {
  p: Paragraph,
  pre: props => <div {...props} />,
  code: Code,
  ul: Ul,
  ol: Ol,
  blockquote: Blockquote,
  a: A,
  audio: Audio,
  img: Img,
};

const BlogPost = props => {
  const postData = props.data.contentfulBlogPost;

  return (
    <Container>
      <TitleBarContainer>
        <Title>{postData.title}</Title>
        <TimeInfo>Published on: {postData.publishedDate}</TimeInfo>
        <TimeInfo>Last updated: {postData.updatedAt}</TimeInfo>
        <SocialMedia
          url={props.location.href}
          title={postData.title}
          tags={postData.tags}
        />
      </TitleBarContainer>
      <MDXProvider components={components}>
        <MDXRenderer>{postData.body.childMdx.body}</MDXRenderer>
      </MDXProvider>
      <BackButton to="/blog">back</BackButton>
    </Container>
  );
};

BlogPost.Layout = Layout;

export default BlogPost;
